<template>
  <div>
    <b-row>
      <b-col cols="12">
        <bread-crumbs :bread-crumbs="breadCrumbs" :currentpage="currentPage" />
      </b-col>

      <b-col cols="12">
        <div class="w-full mb-4 block md:hidden">
          <router-link :to="{ name: 'categories' }"
            >Terug naar categorie overzicht</router-link
          >
        </div>

        <div class="top-bar">
          <h1 v-if="!searchTerm" class="category">
            {{ currentCategory.Description }}
          </h1>
          <h1 v-else class="searchterm">
            {{ $t('item.searchFor') }} {{ searchTerm }}
          </h1>

          <div class="d-none d-md-block">
            <b-button-group class="">
              <b-button
                :class="{ disabled: !gridListing }"
                @click="toggleGridListing"
              >
                <icon-th-list /> Lijst weergave
              </b-button>
              <b-button
                :class="{ disabled: gridListing }"
                @click="toggleGridListing"
              >
                <icon-th /> {{ $t('tile-view') }}
              </b-button>
            </b-button-group>
          </div>
        </div>
        <div
          v-if="
            currentCategory.Description && !searchTerm && currentCategory.Notes
          "
          v-html="currentCategory.Notes"
        ></div>
      </b-col>

      <b-col md="12" lg="3">
        <div v-if="false">
          <label>{{ $t('item.sortBy') }}</label>
          <b-form-select
            id="sortOption"
            v-model="sortOption"
            :options="sortOptions"
            size="sm"
          ></b-form-select>
          <hr />
        </div>

        <sidebar :categories="categories" />
      </b-col>
      <b-col md="12" lg="9">
        <div class="w-100 mt-md-4 mb-md-4">
          <current-rental-information :fullwidth="true" />
          <b-dropdown
            id="dropdown-1"
            text="Bekijk een andere categorie"
            block
            class="m-md-2 block w-full md:hidden mb-3"
          >
            <b-dropdown-item
              v-for="category in parentCategories"
              :key="category.CategoryID"
              :href="`/${$i18n.locale}/shop/${category.CategoryID}`"
              >{{ category.Description }}</b-dropdown-item
            >
          </b-dropdown>
          <search-bar />
        </div>

        <div v-if="loading && items.length < 1" class="loading-container">
          <icon-spinner v-if="loading" />
        </div>

        <b-row v-if="gridListing" class="row">
          <b-col
            v-for="item in items"
            :key="item.ID"
            xs="12"
            md="4"
            class="mb-4"
          >
            <Item :item="item" />
          </b-col>
        </b-row>
        <b-row v-if="!gridListing" class="row">
          <b-col v-for="item in items" :key="item.ID" cols="12">
            <item-horizontal :item="item" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { getItems } from '@/services/ItemService'
import Item from '@/components/items/Item'
import ItemHorizontal from '@/components/items/Item-horizontal'

import SideBar from '@/components/sidebar/sidebar'
import { generateBreadCrumbs } from '@/services/Breadcrumbs'
import IconThList from '@/components/icons/IconThList'
import IconTh from '@/components/icons/IconTh'
import SearchBar from '@/components/global/SearchBar'
import CurrentRentalInformation from '@/components/header/CurrentRentalInformation'
import IconSpinner from '@/components/icons/IconSpinner'
import { mapGetters } from 'vuex'

export default {
  name: 'Items',
  components: {
    IconSpinner,
    CurrentRentalInformation,
    SearchBar,
    IconTh,
    IconThList,
    Item,
    ItemHorizontal,
    sidebar: SideBar
  },

  props: {
    searchTerm: {
      type: String,
      required: false,
      default: function() {
        return ''
      }
    }
  },
  events: {
    confirm: function(argument) {
      this.calendar.startDate = argument
    },
    checkOutChanged: function(argument) {
      this.calendar.endDate = argument
    }
  },
  data() {
    return {
      items: [],
      index: 1,
      loading: true,
      size: 900,
      total: 1,
      gettingData: false,

      breadCrumbs: null,
      dateSelection: '',
      sortOption: null,

      sortOptions: [
        { value: null, text: this.$t('item.sort.default') },
        { value: 'az', text: 'A-Z' },
        { value: 'za', text: 'Z-A' },
        { value: 'price-low-high', text: this.$t('item.sort.pricelowhigh') },
        { value: 'price-high-low', text: this.$t('item.sort.pricehighlow') }
      ]
    }
  },
  metaInfo() {
    return {
      title: `${this.currentCategory?.Description ?? 'Home'}`
    }
  },
  computed: {
    currentPage() {
      if (this.currentCategory !== '' && !this.searchTerm) {
        return this.currentCategory.Description
      }

      if (this.searchTerm) {
        return `${this.$t('search-for')} ${this.searchTerm}`
      }

      return ''
    },
    pages: function() {
      return Math.ceil(this.total / this.size)
    },
    ...mapGetters('categories', {
      categories: 'getCategories'
    }),
    parentCategories: function() {
      return this.$store.getters['categories/getCategories'].filter(
        category => category.ParentCategoryID === null
      )
    },
    currentCategory() {
      if (this.categoryID) {
        return this.$store.getters['categories/getCategory'](this.categoryID)
      }
      const defaultCategories =
        this.$store.getters['categories/getDefaultCategories'] ??
        this.$store.state.categories.defaultCategory

      return defaultCategories
    },
    categoryID: function() {
      if (this.$route.params.categoryID != undefined) {
        return this.$route.params.categoryID
      } else {
        return null
      }
    },
    gridListing: function() {
      return this.$store.getters.gridListing
    }
  },
  watch: {
    $route: function() {
      this.loadData()
    },
    index: async function(val) {
      if (this.gettingData) {
        return
      }
      this.gettingData = true

      this.loading = true

      await this.updateItemData({ index: val })

      this.loading = false

      this.gettingData = false
    },
    sortOption: async function(val) {
      let orderBy = null
      let sortReverse = null

      if (val === 'az') {
        orderBy = 'Description'
        sortReverse = false
      }

      if (val === 'za') {
        orderBy = 'Description'
        sortReverse = true
      }

      if (val === 'price-low-high') {
        orderBy = 'WeekPrice'
        sortReverse = true
      }

      if (val === 'price-high-low') {
        orderBy = 'WeekPrice'
        sortReverse = false
      }

      this.updateItemData({ orderBy, sortReverse })
    },
    categoryID: async function() {
      this.items = []

      if (this.searchTerm && this.searchTerm.length > 1) {
        this.breadCrumbs = await generateBreadCrumbs({
          categoryID: this.currentCategory.categoryID
        })
      }
      await this.updateItemData()
    },
    searchTerm: async function(val) {
      if (this.gettingData) {
        return
      }
      this.gettingData = true

      await this.updateItemData({ search: val })

      this.gettingData = false
    }
  },
  async created() {
    if (!this.categoryID) {
      await this.$store.dispatch('categories/fetchDefaultCategory')
    }
    this.$store.dispatch('categories/fetchCategories')
    this.loadData()
  },
  methods: {
    async loadData() {
      this.setBreadCrumbs()

      await this.updateItemData()
    },
    async setBreadCrumbs() {
      if (this.currentCategory.ParentCategoryID) {
        this.breadCrumbs = await generateBreadCrumbs({
          categoryID: this.currentCategory.CategoryID
        })
      } else {
        this.breadCrumbs = await generateBreadCrumbs()
      }
    },

    async updateItemData({
      size = this.size,
      index = this.index,
      search = this.searchTerm,
      categoryID = this.currentCategory.CategoryID,
      orderBy = null,
      sortReverse = null
    } = {}) {
      if (search != null) {
        categoryID = null
      }

      let items = await getItems({
        size,
        index,
        search,
        categoryID,
        orderBy,
        sortReverse,
        warehouseID: this.$store.state.activeWarehouse
      })

      this.items = items.data.Collection

      this.size = items.data.Size
      this.total = items.data.Total
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    toggleGridListing() {
      this.$store.commit('toggleGridListing')
    }
  }
}
</script>
<style scoped>
.loading-container {
  display: flex;
  width: 100%;
  font-size: 40px;
  justify-content: center;
}
.category,
.searchterm {
  font-weight: 700;
  font-size: 36px;
  margin-bottom: -35px;
}

.top-bar {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
}
</style>
